import { DyteMeeting } from '@hpx-it/dyte-video-room';
import { logger } from '@hpx-it/react-app';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material';
import { RemoteAssistContext } from 'contexts/remoteAssistContext';
import { useContext, useEffect } from 'react';

import { getVideoRoomBoxStyle } from './style';

export const VideoRoomScreen = () => {
  const { remoteAssist, participantToken, createParticipantToken } =
    useContext(RemoteAssistContext);
  const theme = useTheme();

  useEffect(() => {
    if (!participantToken) {
      createParticipantToken();
    }
  }, [createParticipantToken, participantToken]);

  return (
    <Box {...getVideoRoomBoxStyle()}>
      <DyteMeeting
        remoteAssistId={remoteAssist?.id ?? ''}
        token={participantToken?.token}
        participantName={
          remoteAssist?.attendees?.[0].contact?.first_name ?? 'Resident'
        }
        primaryColour={theme.palette.primary.main}
        logger={logger}
      />
    </Box>
  );
};
