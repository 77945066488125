import { Box, Button, Dialog, DialogTitle, SvgIcon } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { ComponentProps } from 'react';

export const getDateButtonBoxStyle = (): ComponentProps<typeof Box> => ({
  alignItems: 'center',
  sx: {
    marginY: '0.5rem',
  },
});

export const getDateButtonStyle = ({
  onClick,
}: {
  onClick: () => void;
}): ComponentProps<typeof Button> => ({
  variant: 'outlined',
  color: 'primary',
  onClick,
  sx: {
    pr: 0.5,
    borderRadius: '8px',
    boxShadow: 2,
    fontSize: '0.9rem',
    whiteSpace: 'pre-line',
    textTransform: 'none',
  },
});

export const getCalendarDialogStyle = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ComponentProps<typeof Dialog> => ({
  open,
  onClose,
  PaperProps: {
    sx: {
      borderRadius: '12px',
      overflow: 'hidden',
      maxWidth: '90%',
      width: 'auto',
    },
  },
});

export const getCalendarDialogTitleStyle = (): ComponentProps<
  typeof DialogTitle
> => ({
  sx: {
    fontSize: '1.25rem',
    fontWeight: 600,
    paddingBottom: '0.5rem',
    alignItems: 'center',
  },
});

export const getCalendarStyle = ({
  selectedDate,
  shouldDisableDate,
  onChange,
}: {
  selectedDate: DateTime;
  onChange: (date: DateTime) => void;
  shouldDisableDate: (date: DateTime) => boolean;
}): ComponentProps<typeof DateCalendar> => ({
  sx: {
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  },
  views: ['day'],
  value: selectedDate,
  disableHighlightToday: true,
  shouldDisableDate,
  onChange,
});

export const getCalendarBoxStyle = (): ComponentProps<typeof Box> => ({
  sx: { display: 'flex', justifyContent: 'center', width: '100%' },
});

export const getUnfoldIconBoxStyle = (): ComponentProps<typeof Box> => ({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const getUnfoldIconStyle = (): ComponentProps<typeof SvgIcon> => ({
  sx: { ml: 1 },
});
