import { UnfoldMore } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { DateTime } from 'luxon';

import {
  getDateButtonBoxStyle,
  getDateButtonStyle,
  getUnfoldIconBoxStyle,
  getUnfoldIconStyle,
} from './style';

type DateButtonProps = {
  selectedDate: DateTime;
  setCalendarOpen: (open: boolean) => void;
  dateFormat: string;
};

export const DateButton = ({
  selectedDate,
  setCalendarOpen,
  dateFormat,
}: DateButtonProps) => {
  return (
    <Box {...getDateButtonBoxStyle()}>
      <Button
        {...getDateButtonStyle({
          onClick: () => setCalendarOpen(true),
        })}
      >
        {selectedDate.toFormat(dateFormat)}
        <Box {...getUnfoldIconBoxStyle()}>
          <UnfoldMore {...getUnfoldIconStyle()} />
        </Box>
      </Button>
    </Box>
  );
};
