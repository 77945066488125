import { Ticket } from '@hpx-it/queue-client';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { QueueContext } from 'contexts';
import { useContext } from 'react';

import { JoinQueueForm } from './JoinQueueForm';
import {
  getAvailableTimeslotsGridStyle,
  getDividerStyle,
  getDividerTextStyle,
  getTicketPaperStyle,
  getTimeslotExplanationStyle,
  getTimeslotTitleStyle,
} from './style';
import { TimeslotForm } from './TimeslotForm';

export const AddTicketTimeslotCard = ({
  ticket,
  setTicket,
}: {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
}) => {
  const { canJoinQueue } = useContext(QueueContext);

  return (
    <Paper {...getTicketPaperStyle()}>
      {canJoinQueue && ticket && (
        <>
          <JoinQueueForm ticket={ticket} setTicket={setTicket} />
          <Divider {...getDividerStyle()}>
            <Typography {...getDividerTextStyle()}>Alternatively...</Typography>
          </Divider>
        </>
      )}

      <Typography {...getTimeslotTitleStyle()}>Select a Timeslot</Typography>
      <Typography {...getTimeslotExplanationStyle()}>
        We'll send you a link to join a video call within the times provided.
      </Typography>
      <Grid {...getAvailableTimeslotsGridStyle()}>
        <TimeslotForm ticket={ticket} setTicket={setTicket} />
      </Grid>
    </Paper>
  );
};
