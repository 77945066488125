import { ConflictError, HttpError } from '@hpx-it/errors';
import { Ticket, queue } from '@hpx-it/queue-client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { QueueContext } from 'contexts';
import { useLoadingButton } from 'hooks';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useContext } from 'react';

import { getFormattedDate, getFormattedTime } from '../format';
import {
  getDialogBoxStyle,
  getDialogConfirmStyle,
  getDialogExitStyle,
} from './style';

const errorToastCodes = [400, 409];

type SubmitTimeslotDialogProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedTimeslot: queue.AvailableTimeslot;
  setShowErrorToast: Dispatch<SetStateAction<boolean>>;
};

export const SubmitTimeslotDialog = ({
  ticket,
  setTicket,
  open,
  setOpen,
  selectedTimeslot,
  setShowErrorToast,
}: SubmitTimeslotDialogProps) => {
  const { refetchTimeslots } = useContext(QueueContext);

  const loadingButtonProps = useLoadingButton(async () => {
    if (selectedTimeslot !== undefined) {
      try {
        setTicket(
          await ticket.schedule({
            start: selectedTimeslot.start,
            end: selectedTimeslot.end,
          }),
        );
      } catch (err) {
        if (err instanceof HttpError && errorToastCodes.includes(err.status)) {
          setShowErrorToast(true);
          refetchTimeslots();
        }
      } finally {
        setOpen(false);
      }
    }
  });

  const handleSubmitTimeslotDialogClose = async () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleSubmitTimeslotDialogClose}>
      <Box {...getDialogBoxStyle()}>
        <DialogTitle>Submit Timeslot</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm that you will be home and available for a video call
            within the timeslot below.
          </DialogContentText>
          <br />
          <DialogContentText>
            {`${getFormattedDate(selectedTimeslot.start)}: ${getFormattedTime(
              DateTime.fromJSDate(selectedTimeslot.start),
            )} — ${getFormattedTime(
              DateTime.fromJSDate(selectedTimeslot.end),
            )}`}
          </DialogContentText>
          <br />
          <DialogContentText>
            We'll send you a text message with a link to join your video call
            when it's about to start.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmitTimeslotDialogClose}
            {...getDialogExitStyle()}
          >
            Exit
          </Button>
          <Button {...getDialogConfirmStyle()} {...loadingButtonProps}>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
