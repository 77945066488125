import {
  QueueFacade,
  Ticket,
  TicketFactory,
  getEndOfDayUTC,
  queue,
  queueBooleanStrategies,
  queueGetNumberStrategies,
  queueSetStrategies,
} from '@hpx-it/queue-client';
import { useInterval } from '@hpx-it/react-app';
import { DeveloperApiClientContext } from 'contexts/developerApiClientContext';
import { RemoteAssistContext } from 'contexts/remoteAssistContext';
import { useTicket } from 'hooks';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type QueueContextProps = {
  ticket: Ticket | undefined;
  setTicket: (ticket: Ticket | undefined) => void;
  loadingTickets: boolean;
  routeToVideoRoom: boolean;
  setRouteToVideoRoom: Dispatch<SetStateAction<boolean>>;
  availableTimeslots: queue.AvailableTimeslot[];
  canJoinQueue: boolean;
  refetchTimeslots: () => void;
};

export type QueueProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: QueueContextProps = {
  ticket: undefined,
  setTicket: () => {},
  loadingTickets: false,
  routeToVideoRoom: false,
  setRouteToVideoRoom: () => {},
  availableTimeslots: [],
  canJoinQueue: false,
  refetchTimeslots: () => {},
};

export const QueueContext = createContext<QueueContextProps>(DEFAULT_CONTEXT);

export const QueueProvider = ({ children }: QueueProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);
  const { remoteAssistId, setTermsAccepted } = useContext(RemoteAssistContext);
  const [loadingTickets, setLoadingTickets] = useState(true);
  const [routeToVideoRoom, setRouteToVideoRoom] = useState(false);

  const [ticket, setTicket] = useTicket();
  const [canJoinQueue, setCanJoinQueue] = useState<boolean>(false);
  const [availableTimeslots, setAvailableTimeslots] = useState<
    queue.AvailableTimeslot[]
  >([]);

  const queueFacade = useMemo(() => {
    try {
      return new QueueFacade(
        new TicketFactory({
          isStrategies: queueBooleanStrategies,
          getNumberStrategies: queueGetNumberStrategies,
          setStrategies: queueSetStrategies,
        }),
        getDeveloperApiClient(),
        () => new Date(),
        () => new Date(getEndOfDayUTC()),
      );
    } catch {}
  }, [getDeveloperApiClient]);

  const refetchTickets = useCallback(async () => {
    if (!queueFacade) {
      return;
    }

    (async () => {
      try {
        const tickets = await queueFacade.getTicketsForRemoteAssist(
          remoteAssistId,
        );

        if (tickets.length > 0) {
          setTicket(tickets[0]);
        }
      } finally {
        setLoadingTickets(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueFacade, remoteAssistId]);

  const refetchTimeslots = useCallback(async () => {
    (async () => {
      try {
        const response = await getDeveloperApiClient().getTimeslots();
        setCanJoinQueue(response.immediate_available);
        setAvailableTimeslots(
          response.timeslots.map((timeslot) => ({
            start: new Date(timeslot.start),
            end: new Date(timeslot.end),
          })),
        );
      } catch {}
    })();
  }, [getDeveloperApiClient]);

  useEffect(() => {
    (async () => {
      if (ticket?.isLive()) {
        setRouteToVideoRoom(true);
      }

      if (ticket?.hasVideoConsent() && ticket.hasSmsConsent()) {
        setTermsAccepted(true);
      }
    })();
  }, [setTermsAccepted, ticket]);

  useEffect(() => {
    refetchTickets();
  }, [refetchTickets]);

  useEffect(() => {
    refetchTimeslots();
  }, [refetchTimeslots]);

  useInterval(() => {
    refetchTickets();
  }, 30000);

  return (
    <QueueContext.Provider
      value={{
        ticket,
        setTicket,
        loadingTickets,
        routeToVideoRoom,
        setRouteToVideoRoom,
        availableTimeslots,
        canJoinQueue,
        refetchTimeslots,
      }}
    >
      {children}
    </QueueContext.Provider>
  );
};
