import { Ticket, queue } from '@hpx-it/queue-client';
import { Alert, Box, Button, Snackbar, Stack } from '@mui/material';
import { QueueContext } from 'contexts';
import { useContext, useState } from 'react';

import { SelectTimeslot } from './SelectTimeslot/SelectTimeslot';
import { getButtonBoxStyle, getSubmitButtonStyle } from './style';
import { SubmitTimeslotDialog } from './SubmitTimeslotDialog';
import { TimeslotErrorSnackbar } from './TimeslotErrorSnackbar';

type TimeslotFormProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
};

export const TimeslotForm = ({ ticket, setTicket }: TimeslotFormProps) => {
  const { availableTimeslots } = useContext(QueueContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);

  const [selectedTimeslot, setSelectedTimeslot] = useState<
    queue.AvailableTimeslot | undefined
  >(undefined);

  const disableButton = selectedTimeslot === undefined;

  return (
    <Stack>
      <SelectTimeslot
        timeslots={availableTimeslots}
        selectedTimeslot={selectedTimeslot}
        setSelectedTimeslot={setSelectedTimeslot}
        dateFormat={'ccc, LLL d'}
      />
      <Box {...getButtonBoxStyle()}>
        <Button
          {...getSubmitButtonStyle({
            disabled: disableButton,
          })}
          onClick={() => setDialogOpen(true)}
        >
          Submit
        </Button>
      </Box>
      {!disableButton && (
        <SubmitTimeslotDialog
          ticket={ticket}
          setTicket={setTicket}
          open={dialogOpen}
          setOpen={setDialogOpen}
          selectedTimeslot={selectedTimeslot}
          setShowErrorToast={setShowErrorToast}
        />
      )}
      <TimeslotErrorSnackbar
        open={showErrorToast}
        setOpen={setShowErrorToast}
      />
    </Stack>
  );
};
